import {Component, OnInit} from '@angular/core';
import {AgenciesService, Agency} from './agencies.service';
import {MatDialog} from '@angular/material';
import {AgencyEditComponent} from './agency-edit/agency-edit.component';
import {LoginService} from '../login/login.service';
import {BehaviorSubject} from 'rxjs';
import {OfficesService} from '../offices/offices.services';
import {map, switchMap, tap} from 'rxjs/operators';
import {ImportComponent} from '../import/import.component';
import {ConfirmDialogComponent} from '../shared/confirm-dialog/confirm-dialog.component';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-agencies',
  templateUrl: './agencies.component.html',
  styleUrls: ['./agencies.component.css']
})
export class AgenciesComponent implements OnInit {
  agencies: Agency[] = [];
  officeId: BehaviorSubject<string>;
  inProgress = false;

  constructor(private agenciesService: AgenciesService, private dialog: MatDialog, private toaster: ToastrService,
              private loginService: LoginService, private officesService: OfficesService) {
  }

  ngOnInit() {
    this.updateAgencies();
  }

  onAddClick() {
    const dialogRef = this.dialog.open(AgencyEditComponent, {
      width: '48%',
      data: {
        done: () => dialogRef.close()
      }
    });

    dialogRef.afterClosed()
      .subscribe(() => this.updateAgencies());
  }

  updateAgencies() {
    this.inProgress = true;
    this.officesService.currentOffice
      .pipe(map(office => office.id || this.loginService.user.getValue().officeId))
      .pipe(switchMap(officeId => this.agenciesService.getAgencies(officeId)))
      .pipe(tap(() => this.inProgress = false))
      .subscribe(agencies => this.agencies = agencies);
  }

  onImportClick() {
    const dialogRef = this.dialog.open(ImportComponent, {
      width: '800px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(() => this.updateAgencies());
  }

  onDeleteClick(agency: Agency) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: {
        title: `Delete agency ${agency.name}`,
        message: `Are you sure you want to delete agency?`,
        buttons: {
          YES: {
            click: () => {
              this.agenciesService.delete(agency.id)
                .pipe(map(() => this.updateAgencies()))
                .subscribe(() => {
                  dialogRef.close();
                  this.toaster.success(`Agency ${agency.name} successfully deleted`, 'Delete agency');
                });
            }
          },
          NO: {
            click: () => {
              dialogRef.close();
            }
          }
        }
      }
    });

    dialogRef.afterClosed().subscribe(() => this.updateAgencies());
  }
}
