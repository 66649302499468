import {Routes} from '@angular/router';
import {FullComponent} from './layouts/full/full.component';
import {LoginComponent} from './login/login.component';
import {AgencyComponent} from './agencies/agency/agency.component';
import {AgenciesComponent} from './agencies/agencies.component';
import {DirectoriesComponent} from './directories/directories.component';
import {DirectoryComponent} from './directories/directory/directory.component';
import {ErrorComponent} from './error/error.component';
import {UsersComponent} from './users/users.component';

export const AppRoutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        redirectTo: 'agencies',
        pathMatch: 'full'
      },
      {
        path: 'agencies',
        component: AgenciesComponent
      },
      {
        path: 'agencies/:agencyId',
        component: AgencyComponent
      },
      {
        path: 'directories',
        component: DirectoriesComponent
      },
      {
        path: 'directories/:id',
        component: DirectoryComponent
      },
      {
        path: 'users',
        component: UsersComponent
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '**',
    component: ErrorComponent
  }
];
