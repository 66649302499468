import { Component, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DirectoriesService } from '../directories/directories.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { AddNewControlComponent } from '../directory-edit/add-new-control/add-new-control.component';
import { AddNewControlService } from './add-new-control/add-new-control.service';

@Component({
  selector: 'app-directory-edit',
  templateUrl: './directory-edit.component.html',
  styleUrls: ['./directory-edit.component.css']
})
export class DirectoryEditComponent implements OnInit {
  readonly directoryForm: FormGroup;
  @Output() submissionData: any;
  tempData: any;
  propertyNames: string[];
  jsonForm: { [key: string]: string };
  form: FormGroup;

  constructor(
    private directoriesService: DirectoriesService,
    public dialogRef: MatDialogRef<DirectoryEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public addControlService: AddNewControlService
  ) {
    this.directoryForm = new FormGroup({
      name: new FormControl(data.name),
      url: new FormControl(data.url),
      description: new FormControl(data.description),
      loginUrl: new FormControl(data.loginUrl),
      method: new FormControl(data.method || 'post'),
      successStatus: new FormControl(data.successStatus || 302, [
        Validators.min(100),
        Validators.max(600)
      ]),
      submissionData: new FormControl(
        data.submissionData ||
          '{"username": "${login}", "password": "${password}"}'
      )
    });

    this.submissionData = this.directoryForm.controls.submissionData.value;
  }
  addControl(): void {
    this.addControlService.addControlForm = this.dialog.open(
      AddNewControlComponent,
      {
        width: '48%',
        data: {}
      }
    );
  }

  onClick(): void {
    this.jsonForm = JSON.parse(this.tempData);
    this.propertyNames = Object.keys(this.jsonForm);
    let update$;
    if (this.data.id) {
      this.directoryForm.removeControl('submissionData');
      this.directoryForm.addControl(
        'submissionData',
        new FormControl(this.tempData)
      );
      update$ = this.directoriesService.update(
        this.data.id,
        this.directoryForm.getRawValue()
      );
    } else {
      update$ = this.directoriesService.create(
        this.directoryForm.getRawValue()
      );
    }

    update$.subscribe(() => this.dialogRef.close());
  }

  ngOnInit() {}
}

export interface DialogData {
  name: string;
  url: string;
  description: string;
  loginUrl: any;
  method: string;
  successStatus: number;
  submissionData: string;
}
