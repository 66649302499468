import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../environments/environment';
import {filter, map} from 'rxjs/operators';
import {ActivationStart, Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LocationsService {
  readonly currentLocations: Subject<Location[]>;

  constructor(private http: HttpClient, private router: Router) {
    this.currentLocations = new Subject();

    this.router.events
      .pipe(filter(event => event instanceof ActivationStart))
      .pipe(map((event: ActivationStart) => event.snapshot.params.agencyId))
      .subscribe(agencyId => {
        if (!agencyId) {
          this.currentLocations.next([]);
        }
      });
  }

  getLocations(agencyId: string): Observable<Location[]> {
    return this.http.get(`${environment.api.baseUrl}/agencies/${agencyId}/locations`,
      {
        withCredentials: true
      })
      .pipe(map(response => response['content'].locations));
  }

  create(agencyId: string, location: Location) {
    return this.http.post(`${environment.api.baseUrl}/agencies/${agencyId}/locations`, {data: location}, {
      withCredentials: true,
      responseType: 'text'
    });
  }

  remove(id: string) {
    return this.http.delete(`${environment.api.baseUrl}/locations/${id}`, {
      withCredentials: true
    });
  }
}

export interface Location {
  id: string;
  name: string;
  country: string;
  city: string;
  description: string;
}
