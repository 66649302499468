import {Injectable} from '@angular/core';
import {LoginService} from '../../login/login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authenticated = true;

  constructor(private loginService: LoginService) {
  }

  get isAuthenticated() {
    return this.authenticated;
  }

  set isAuthenticated(authenticated: boolean) {
    this.authenticated = authenticated;

    if (!this.authenticated) {
      this.loginService.logout()
        .subscribe();
    }
  }
}
