import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AgenciesService} from '../agencies.service';
import {MAT_DIALOG_DATA} from '@angular/material';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-agency-edit',
  templateUrl: './agency-edit.component.html',
  styleUrls: ['./agency-edit.component.css']
})
export class AgencyEditComponent implements OnInit {

  isLinear = false;
  bizInfoFormGroup: FormGroup;
  mediaFormGroup: FormGroup;
  locationId: string;

  constructor(private formBuilder: FormBuilder, private agenciesService: AgenciesService,
              @Inject(MAT_DIALOG_DATA) public data: any, private routes: ActivatedRoute) {
  }

  ngOnInit() {
    this.bizInfoFormGroup = this.formBuilder.group({
      name: [this.data.name, Validators.required],
      address: [this.data.address, Validators.required],
      description: [this.data.description, Validators.required]
    });
    this.mediaFormGroup = this.formBuilder.group({
      secondCtrl: ['', Validators.required]
    });

    this.locationId = this.routes.snapshot.queryParams.location;
  }

  onSubmitClick() {
    let update$;
    if (this.data.id) {
      update$ = this.agenciesService.update(this.data.id, this.bizInfoFormGroup.getRawValue(), this.locationId);
    } else {
      update$ = this.agenciesService.create(this.bizInfoFormGroup.getRawValue());
    }

    update$.subscribe(() => this.data.done());
  }
}
