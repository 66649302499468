import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddNewControlService {
  newControlsArr = [];
  componentMethodCallSource = new Subject<any>();
  addControlForm: any;

  clear() {
    this.newControlsArr = [];
  }

  get() {
    const answer = this.newControlsArr;
    this.clear();
    return answer;
  }

  constructor() {}
}
