import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {Router} from '@angular/router';
import {map, tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private readonly baseUrl = `${environment.api.baseUrl}/users`;

  user = new BehaviorSubject(JSON.parse(localStorage.getItem('user') || '{}'));

  constructor(private http: HttpClient, private router: Router) {
  }

  login(loginData: LoginData): Observable<any> {
    return this.http.post(`${this.baseUrl}/login`, {
        username: loginData.username,
        password: loginData.password
      },
      {
        withCredentials: true
      })
      .pipe(tap(response => {
        const token = response['content'].token;

        if (token) {
          localStorage.setItem('token', token);
        }
      }))
      .pipe(map(response => response['content'].user))
      .pipe(tap(user => localStorage.setItem('user', JSON.stringify(user))))
      .pipe(map((user: User) => this.user.next(user)));
  }

  logout() {
    return this.http.post(`${this.baseUrl}/logout`, {}, {
      responseType: 'text',
      withCredentials: true
    })
      .pipe(map(() => this.router.navigate(['login'])));
  }
}

export interface LoginData {
  username: string;
  password: string;
}

export interface User {
  username: string;
  id: string;
  officeId: string;
}
