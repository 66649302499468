import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MenuItems } from './menu-items/menu-items';
import {
  AccordionAnchorDirective,
  AccordionDirective,
  AccordionLinkDirective
} from './accordion';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ConfirmDialogComponent,
    DynamicFormComponent
  ],
  imports: [
    MatExpansionModule,
    MatFormFieldModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    DynamicFormComponent
  ],
  providers: [MenuItems],
  entryComponents: [ConfirmDialogComponent]
})
export class SharedModule {}
