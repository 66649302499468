import {Component, Inject} from '@angular/core';
import {ErrorStateMatcher, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Listing, ListingsService} from '../listings.service';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {switchMap} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';

export class ListingErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-listing-edit',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.css']
})
export class ListingComponent {
  passwordFormControl = new FormControl(this.data.login, [
    Validators.required
  ]);
  usernameFormControl = new FormControl(this.data.password, [
    Validators.required
  ]);
  matcher = new ListingErrorStateMatcher();
  connecting = false;
  listingConnectionFailed = false;


  constructor(
    public dialogRef: MatDialogRef<ListingComponent>, @Inject(MAT_DIALOG_DATA) public data: Listing,
    private listingsService: ListingsService, private toaster: ToastrService) {
  }

  onConnectClick(): void {
    this.connecting = true;
    this.listingConnectionFailed = false;

    this.listingsService
      .update(this.data.id, {
        login: this.usernameFormControl.value,
        password: this.passwordFormControl.value
      })
      .pipe(switchMap(() => this.listingsService.connect(this.data.id)))
      .subscribe(connected => {
          this.listingConnectionFailed = !connected;
          this.connecting = false;

          if (!this.listingConnectionFailed) {
            this.toaster.success('Listing successfully connected', 'Connect Listing');
            this.dialogRef.close();
          } else {
            this.toaster.error('Unable to connect listing', 'Connect Listing');
          }
        },
        () => {
          this.toaster.error('Unable to connect listing', 'Connect Listing');
          this.listingConnectionFailed = true;
        }
      );
  }
}

