import { Injectable } from '@angular/core';

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
}

const MENUITEMS = [
  { state: 'agencies', name: 'Agencies', type: 'link', icon: 'supervisor_account' },
  { state: 'directories', name: 'Directories', type: 'link', icon: 'widgets' },
  { state: 'users', name: 'Users', type: 'link', icon: 'account_circle' }
];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
