import {Component} from '@angular/core';
import {LoginService} from '../../../login/login.service';
import {Office, OfficesService} from '../../../offices/offices.services';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {
  readonly office: Subject<Office>;

  constructor(private loginService: LoginService, private officesService: OfficesService) {
    this.office = this.officesService.currentOffice;
  }

  logout() {
    this.loginService.logout()
      .subscribe();
  }
}
