import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {forkJoin, Observable} from 'rxjs';
import {DirectoriesService, Directory} from '../directories/directories.service';
import {Listing} from '../listings/listings.service';
import {OfficesService} from '../offices/offices.services';
import {LocationsService} from '../locations/locations.service';

@Injectable({
  providedIn: 'root'
})
export class AgenciesService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient, private officesService: OfficesService, private directoriesService: DirectoriesService,
              private locationsService: LocationsService) {
    this.baseUrl = `${environment.api.baseUrl}/agencies`;
  }

  getAgencies(officeId: string = ''): Observable<Agency[]> {
    return this.http.get(`${this.baseUrl}?office=${officeId}`, {
      withCredentials: true
    })
      .pipe(map(response => response['content'].agencies));
  }

  getAgency(id: string, locationId: string = ''): Observable<Agency> {
    return forkJoin([this.http.get(`${this.baseUrl}/${id}?location=${locationId}`, {
      withCredentials: true
    }), this.directoriesService.getDirectories()])
      .pipe(map<any, any>(responses => {
        const [agencyResponse, directories] = responses;
        return {
          agency: agencyResponse['content'].agency,
          directories
        };
      }))
      .pipe(map<any, any>(response => {
        const {agency, directories} = response;

        const agencyDescription = agency.descriptions[0];
        agency.description = agencyDescription ? agencyDescription.description : '';
        agency.address = agencyDescription ? agencyDescription.address : '';
        agency.name = agencyDescription ? agencyDescription.name : '';

        const notListed = directories.filter(directory => !agency.listings.some(listing => listing.directory.id === directory.id))
          .map(directory => ({
            agencyId: agency.id,
            directoryId: directory.id,
            directory
          }));

        agency.listings = agency.listings.concat(notListed);

        agency.listings = agency.listings.sort((left, right) => {
          if (left.directory.name < right.directory.name) {
            return -1;
          } else if (left.directory.name > right.directory.name) {
            return 1;
          }

          return 0;
        });

        this.locationsService.currentLocations.next(agency.locations || []);

        return agency;
      }));
  }

  create(agency) {
    return this.http.post(this.baseUrl, {
        data: Object.assign({}, agency, {
          officeId: this.officesService.currentOffice.getValue().id
        })
      },
      {
        withCredentials: true,
        responseType: 'text'
      });
  }

  update(id: string, agency: Agency, locationId = '') {
    return this.http.put(`${this.baseUrl}/${id}?location=${locationId}`, {
        data: agency
      },
      {
        withCredentials: true,
        responseType: 'text'
      });
  }

  delete(id: string) {
    return this.http.delete(`${this.baseUrl}/${id}`, {
      withCredentials: true,
      responseType: 'text'
    });
  }
}

export interface Agency {
  id: string;
  name: string;
  address: string;
  description: string;
  directories: Directory[];
  listings: Listing[];
}
