import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {InputFile} from 'ngx-input-file';
import {environment} from '../../environments/environment';
import {OfficesService} from '../offices/offices.services';

@Injectable({
  providedIn: 'root'
})
export class ImportService {

  constructor(private http: HttpClient, private officesService: OfficesService) {
  }

  import(file: InputFile) {
    const formData = new FormData();
    formData.append('file', file.file, file.file.name);
    return this.http.post(`${environment.api.baseUrl}/imports/${this.officesService.currentOffice.getValue().id}`, formData, {
      withCredentials: true,
      reportProgress: true
    });
  }
}
