import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {OfficesService} from '../offices.services';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-office-edit',
  templateUrl: './office-edit.component.html',
  styleUrls: ['./office-edit.component.css']
})
export class OfficeEditComponent implements OnInit {
  officeForm: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<OfficeEditComponent>,
              private officesService: OfficesService, private toaster: ToastrService) {
    this.officeForm = new FormGroup({
      name: new FormControl(this.data.name, [Validators.required]),
      city: new FormControl(this.data.city, [Validators.required]),
      country: new FormControl(this.data.country, [Validators.required]),
    });
  }

  ngOnInit() {
  }

  onSubmit() {
    this.officesService.create(this.officeForm.value)
      .subscribe(() => {
          this.toaster.success(`New office successfully created`, 'Create office');
          this.dialogRef.close();
        },
        () => this.toaster.error('Unable to create new office', 'Create office'));
  }

}
