import {Component, OnInit} from '@angular/core';
import {UsersService} from './users.service';
import {FormControl, FormGroup} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {tap} from 'rxjs/operators';
import {OfficesService} from '../offices/offices.services';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  readonly userForm: FormGroup;
  inProgress = false;
  passwordMinLength = 8;
  offices = [];

  constructor(private usersService: UsersService, private toaster: ToastrService, private officesService: OfficesService) {
    this.userForm = new FormGroup({
      username: new FormControl(''),
      password: new FormControl(''),
      office: new FormControl('')
    });
  }

  ngOnInit() {
    this.officesService.getOffices()
      .subscribe(offices => {
        this.offices = offices;
        this.userForm.controls.office.setValue(offices[0]);
      });
  }

  registerUser() {
    this.inProgress = true;
    const username = this.userForm.controls.username.value;
    const password = this.userForm.controls.password.value;

    this.usersService.createUser({
      username,
      password,
      officeId: this.userForm.controls.office.value
    })
      .pipe(tap(() => this.userForm.reset()))
      .subscribe(() => {
          this.inProgress = false;
          this.toaster.success(`User ${username} successfully created`, 'Create user');
        },
        () => {
          this.inProgress = false;
          this.toaster.error(`Failed to create user ${username}`, 'Create user');
        });
  }
}
