import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.interceptRequest(req));
  }

  private interceptRequest(req: HttpRequest<any>) {
    const storedToken = localStorage.getItem('token') || '';
    const token = `Bearer ${storedToken}`;

    return req.clone({
      headers: req.headers.set('Authorization', token),
    });
  }
}
