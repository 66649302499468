import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OfficesService {

  private readonly apiUrl: string;

  readonly currentOffice: BehaviorSubject<Office>;

  constructor(private http: HttpClient) {
    this.apiUrl = `${environment.api.baseUrl}/offices`;
    this.currentOffice = new BehaviorSubject({});
  }

  getOffices(): Observable<Office[]> {
    return this.http.get(this.apiUrl, {
      withCredentials: true
    })
      .pipe(map(response => response['content'].offices));
  }

  setOffice(office: Office) {
    this.currentOffice.next(office);
  }

  update(id: string, data: Office) {
    return this.http.put(`${this.apiUrl}/${id}`, {data}, {
      withCredentials: true,
      responseType: 'text'
    });
  }

  create(data: Office) {
    return this.http.post(this.apiUrl, {data}, {
      withCredentials: true,
      responseType: 'text'
    });
  }

  delete(id: string) {
    return this.http.delete(`${this.apiUrl}/${id}`, {
      withCredentials: true,
      responseType: 'text'
    });
  }
}

export interface Office {
  id?: string;
  name?: string;
}
