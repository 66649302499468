import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {OfficesService} from '../offices/offices.services';

@Injectable({
  providedIn: 'root'
})
export class DirectoriesService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient, private officesService: OfficesService) {
    this.baseUrl = `${environment.api.baseUrl}/directories`;
  }

  getDirectories(): Observable<Directory[]> {
    return this.http.get(`${this.baseUrl}?office=${this.officesService.currentOffice.getValue().id}`, {
      withCredentials: true
    })
      .pipe(map(response => response['content'].directories))
      .pipe(map(directories => directories.sort((left, right) => {
        if (left.name > right.name) {
          return 1;
        } else if (left.name < right.name) {
          return -1;
        }

        return 0;
      })));
  }

  create(data: any): Observable<any> {
    return this.http.post(this.baseUrl, {data}, {
      withCredentials: true,
      responseType: 'text'
    });
  }

  update(id: string, directory: Directory) {
    return this.http.put(`${this.baseUrl}/${id}`, {
        data: directory
      },
      {
        withCredentials: true,
        responseType: 'text'
      });
  }

  delete(id: string) {
    return this.http.delete(`${this.baseUrl}/${id}`, {
      withCredentials: true,
      responseType: 'text'
    });
  }
}

export interface Directory {
  id: string;
  name: string;
  url: string;
  description: string;
  loginUrl: string;
  method: string;
}
