import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Directory} from '../directories/directories.service';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ListingsService {

  private readonly apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = `${environment.api.baseUrl}/listings`;
  }

  getListing(id: string): Observable<Listing> {
    return this.http.get(`${this.apiUrl}/${id}`, {
      withCredentials: true
    })
      .pipe(map(response => response['content'].listing));
  }

  connect(id: string): Observable<boolean> {
    return this.http.post(`${this.apiUrl}/${id}/verify`, {}, {
      withCredentials: true
    })
      .pipe(map(response => response['content'].verified));
  }

  update(id: string, data: Listing) {
    return this.http.put(`${this.apiUrl}/${id}`, {data}, {
      withCredentials: true,
      responseType: 'text'
    });
  }

  create(data: Listing) {
    return this.http.post(this.apiUrl, {data}, {
      withCredentials: true,
      responseType: 'text'
    });
  }

  delete(id: string) {
    return this.http.delete(`${this.apiUrl}/${id}`, {
      withCredentials: true,
      responseType: 'text'
    });
  }
}

export interface Listing {
  id?: string;
  login?: string;
  password?: string;
  status?: string;
  state?: string;
  comment?: string;
  directory?: Directory;
  agencyId?: string;
  locationId?: string;
  directoryId?: string;
  listingUrl?: string;
}
