import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ListingsService} from '../../listings.service';
import {FormControl, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-listing-edit',
  templateUrl: './listing-edit.component.html',
  styleUrls: ['./listing-edit.component.css']
})
export class ListingEditComponent implements OnInit {
  readonly listingForm: FormGroup;

  constructor(private listingsService: ListingsService, @Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<ActivatedRoute>, private toaster: ToastrService, private route: ActivatedRoute) {
    this.listingForm = new FormGroup({
      login: new FormControl(data.login),
      password: new FormControl(data.password),
      comment: new FormControl(data.comment),
      status: new FormControl(data.status),
      listingUrl: new FormControl(data.listingUrl),
      state: new FormControl(data.state)
    });
  }

  ngOnInit() {
  }

  onSubmit() {
    const listingUpdate$ = this.data.id ? this.listingsService.update(this.data.id, this.listingForm.value)
      : this.listingsService.create(Object.assign({}, this.listingForm.value, {
        agencyId: this.data.agencyId,
        directoryId: this.data.directoryId,
        locationId: this.route.snapshot.queryParamMap.get('location')
      }));

    listingUpdate$.subscribe(() => {
        this.toaster.success(`Listing ${this.data.directory.name} successfully updated.`, 'Listing update');
        this.dialogRef.close();
      },
      () => this.toaster.error(`Listing ${this.data.directory.name} update failed.`, 'Listing update'));
  }
}
