import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppRoutes} from './app.routing';
import {AppComponent} from './app.component';

import {FlexLayoutModule} from '@angular/flex-layout';
import {FullComponent} from './layouts/full/full.component';
import {AppHeaderComponent} from './layouts/full/header/header.component';
import {AppSidebarComponent} from './layouts/full/sidebar/sidebar.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DemoMaterialModule} from './demo-material-module';

import {SharedModule} from './shared/shared.module';
import {SpinnerComponent} from './shared/spinner.component';
import {LoginComponent} from './login/login.component';
import {CoreModule} from './core/core.module';
import {LoginInterceptor} from './login/login.interceptor';
import {AgenciesComponent} from './agencies/agencies.component';
import {AgencyComponent} from './agencies/agency/agency.component';
import {LocationsComponent} from './locations/locations.component';
import {ListingsComponent} from './listings/listings.component';
import {ListingComponent} from './listings/listing/listing.component';
import {DirectoriesComponent} from './directories/directories.component';
import {DirectoryComponent} from './directories/directory/directory.component';
import {ToastrModule} from 'ngx-toastr';
import {ErrorComponent} from './error/error.component';
import {DirectoryEditComponent} from './directory-edit/directory-edit.component';
import {ListingEditComponent} from './listings/listing/listing-edit/listing-edit.component';
import {UsersComponent} from './users/users.component';
import {AgencyEditComponent} from './agencies/agency-edit/agency-edit.component';
import {InputFileModule} from 'ngx-input-file';
import {OfficesComponent} from './offices/offices.component';
import {LocationEditComponent} from './locations/location-edit/location-edit.component';
import {ImportComponent} from './import/import.component';
import {OfficeEditComponent} from './offices/office-edit/office-edit.component';
import {AddNewControlComponent} from './directory-edit/add-new-control/add-new-control.component';
import {AuthInterceptor} from './core/auth/auth.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    AppHeaderComponent,
    SpinnerComponent,
    AppSidebarComponent,
    LoginComponent,
    AgenciesComponent,
    AgencyComponent,
    LocationsComponent,
    ListingsComponent,
    ListingComponent,
    DirectoriesComponent,
    DirectoryComponent,
    ErrorComponent,
    DirectoryEditComponent,
    UsersComponent,
    ListingEditComponent,
    AgencyEditComponent,
    OfficesComponent,
    LocationEditComponent,
    AddNewControlComponent,
    ImportComponent,
    OfficeEditComponent
  ],
  entryComponents: [
    ListingComponent,
    ListingEditComponent,
    DirectoryEditComponent,
    AgencyEditComponent,
    LocationEditComponent,
    AddNewControlComponent,
    ImportComponent,
    OfficeEditComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    FormsModule,
    FlexLayoutModule,
    HttpClientModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    RouterModule.forRoot(AppRoutes, {useHash: true}),
    ToastrModule.forRoot(),
    InputFileModule.forRoot({})
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoginInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
