import {Component, OnInit} from '@angular/core';
import {Location, LocationsService} from './locations.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.css']
})
export class LocationsComponent implements OnInit {
  locations: Location[] = [];
  active: false;
  activeLocation = '';

  constructor(private locationsService: LocationsService, private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.locationsService.currentLocations
      .subscribe(locations => {
        this.locations = locations;
        this.activeLocation = this.route.snapshot.queryParams.location;

        if (!this.activeLocation && locations) {
          const [defaultLocation] = locations;
          this.navigateToLocation(defaultLocation.id);
        }
      });

    this.route.queryParams.subscribe(queryParams => this.activeLocation = queryParams.location);
  }

  selectLocation(location: Location) {
    return this.navigateToLocation(location.id);
  }

  private navigateToLocation(id: string) {
    const urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams['location'] = id;
    return this.router.navigateByUrl(urlTree);
  }
}
