import {Component, OnInit} from '@angular/core';
import {AgenciesService} from '../agencies.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import {Listing, ListingsService} from '../../listings/listings.service';
import {ListingComponent} from '../../listings/listing/listing.component';
import {DirectoriesService, Directory} from '../../directories/directories.service';
import {map, tap} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {ConfirmDialogComponent} from '../../shared/confirm-dialog/confirm-dialog.component';
import {AgencyEditComponent} from '../agency-edit/agency-edit.component';
import {ListingEditComponent} from '../../listings/listing/listing-edit/listing-edit.component';
import {DomSanitizer} from '@angular/platform-browser';
import {LocationEditComponent} from '../../locations/location-edit/location-edit.component';
import {LocationsService, Location} from '../../locations/locations.service';

@Component({
  selector: 'app-agency',
  templateUrl: './agency.component.html',
  styleUrls: ['./agency.component.css']
})
export class AgencyComponent implements OnInit {
  agency: any = {};
  listings: any = [];
  id: string;
  locationId: string;
  connectInProgress = false;
  directories: Directory[] = [];
  status = '';
  listedCount: number;
  notListedCount: number;
  requireActionCount: number;
  inProgress = false;
  currentLocation: Location;

  constructor(private agenciesService: AgenciesService, private routes: ActivatedRoute, private listingsService: ListingsService,
              private dialog: MatDialog, private directoriesService: DirectoriesService, private toaster: ToastrService,
              private sanitizer: DomSanitizer, private locationsService: LocationsService, private router: Router) {
  }

  ngOnInit() {
    this.routes.params
      .subscribe(params => {
        this.id = params.agencyId;
        this.locationId = this.routes.snapshot.queryParams.location;
        this.updateAgency(this.id, this.locationId);
      });

    this.routes.queryParams
      .subscribe(params => {
        this.locationId = params.location;
        this.updateAgency(this.id, this.locationId);
      });

    this.directoriesService.getDirectories()
      .subscribe(directories => {
        this.directories = directories;
      });

    this.locationsService.currentLocations
      .pipe(tap(locations => this.currentLocation = locations.find(location => `${location.id}` === `${this.locationId}`)))
      .subscribe();
  }

  updateAgency(agencyId: string, locationId: string) {
    this.inProgress = true;
    this.agenciesService.getAgency(agencyId, locationId)
      .pipe(tap(() => this.inProgress = false))
      .subscribe(agency => {
        this.agency = agency;
        this.listings = agency.listings;
        this.status = '';
        this.listedCount = this.getListedListings().length;
        this.notListedCount = this.getNotListedListings().length;
        this.requireActionCount = this.getRequireActionListings().length;
      });
  }

  connectListing(listing: Listing) {
    this.connectInProgress = true;

    this.openDialog(listing);
  }

  openDialog(listing: Listing): void {
    const dialogRef = this.dialog.open(ListingComponent, {
      width: '800px',
      data: listing
    });

    dialogRef.afterClosed()
      .subscribe(() => this.updateAgency(this.agency.id, this.locationId));
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  onAddLocationClick() {
    const dialogRef = this.dialog.open(LocationEditComponent, {
      width: '800px',
      data: {
        agencyId: this.agency.id
      }
    });

    dialogRef.afterClosed()
      .subscribe(() => this.updateAgency(this.agency.id, this.locationId));
  }

  onRemoveLocationClick() {
    this.locationsService.remove(this.locationId)
      .pipe(map(() => this.router.navigate(['agencies', this.agency.id])))
      .subscribe();
  }

  onDeleteListingClick(listing: Listing) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: {
        title: `Delete listing ${listing.directory.name}`,
        message: `Are you sure you want to delete listing?`,
        buttons: {
          YES: {
            click: () => {
              this.listingsService.delete(listing.id)
                .pipe(map(() => this.updateAgency(this.agency.id, this.locationId)))
                .subscribe(() => {
                  dialogRef.close();
                  this.toaster.success(`Listing ${listing.directory.name} successfully deleted`, 'Delete listing');
                });
            }
          },
          NO: {
            click: () => {
              dialogRef.close();
            }
          }
        }
      }
    });
  }

  updateListings(status: string) {
    if (this.status === status) {
      this.listings = this.agency.listings;
      this.status = '';
      return;
    }

    switch (status) {
      case 'listed': {
        this.listings = this.getListedListings();
        break;
      }
      case 'notListed': {
        this.listings = this.getNotListedListings();
        break;
      }
      case 'requireAction': {
        this.listings = this.getRequireActionListings();
      }
    }
    this.status = status;
  }

  onEditClick() {
    const dialogRef = this.dialog.open(AgencyEditComponent, {
      width: '800px',
      data: Object.assign({}, this.agency, {
        done: () => dialogRef.close()
      })
    });

    dialogRef.afterClosed()
      .subscribe(() => this.updateAgency(this.agency.id, this.locationId));
  }

  onEditListingClick(listing: Listing) {
    const dialogRef = this.dialog.open(ListingEditComponent, {
      width: '800px',
      data: listing
    });

    dialogRef.afterClosed()
      .subscribe(() => this.updateAgency(this.agency.id, this.locationId));
  }

  private getListedListings() {
    return this.listings.filter(listing => listing.id && listing.listingUrl);
  }

  private getNotListedListings() {
    return this.listings.filter(listing => !listing.id || !listing.listingUrl);
  }

  private getRequireActionListings() {
    return this.listings.filter(listing => listing.state !== 'done' && listing.state !== 'yes');
  }
}
