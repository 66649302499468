import {Component, OnInit} from '@angular/core';
import {DirectoriesService, Directory} from './directories.service';
import {MatDialog} from '@angular/material';
import {DirectoryEditComponent} from '../directory-edit/directory-edit.component';
import {ToastrService} from 'ngx-toastr';
import {ConfirmDialogComponent} from '../shared/confirm-dialog/confirm-dialog.component';
import {DomSanitizer} from '@angular/platform-browser';
import {OfficesService} from '../offices/offices.services';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'app-directories',
  templateUrl: './directories.component.html',
  styleUrls: ['./directories.component.css']
})
export class DirectoriesComponent implements OnInit {
  directories: Directory[] = [];
  inProgress = false;

  constructor(private directoriesService: DirectoriesService, public dialog: MatDialog, private toaster: ToastrService,
              private sanitizer: DomSanitizer, private officesService: OfficesService) {
  }

  ngOnInit() {
    this.updateDirectories();

    this.officesService.currentOffice
      .subscribe(() => this.updateDirectories());
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DirectoryEditComponent, {
      width: '48%',
      data: {}
    });
    dialogRef.afterClosed()
      .subscribe(() => this.updateDirectories());
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  openDirectory(directory) {
    const dialogRef = this.dialog.open(DirectoryEditComponent, {
      width: '48%',
      data: directory
    });

    dialogRef.afterClosed()
      .subscribe(() => this.updateDirectories());
  }

  onDeleteClick(directory) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: {
        title: `Delete directory`,
        message: `Are you sure you want to delete ${directory.name} directory?`,
        buttons: {
          YES: {
            click: () => {
              this.directoriesService.delete(directory.id)
                .subscribe(() => {
                    this.updateDirectories();
                    dialogRef.close();
                    this.toaster.success('Delete directory', `Directory ${directory.name} successfully deleted`);
                  },
                  () => {
                    dialogRef.close();
                    this.toaster.error(`Directory ${directory.name} can't be deleted until it has associated listings`, 'Delete directory');
                  });
            }
          },
          NO: {
            click: () => {
              dialogRef.close();
            }
          }
        }
      }
    });
  }

  private updateDirectories() {
    this.inProgress = true;
    this.directoriesService
      .getDirectories()
      .pipe(tap(() => this.inProgress = false))
      .subscribe(directories => this.directories = directories);
  }
}
