import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ImportService} from './import.service';
import {ToastrService} from 'ngx-toastr';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.css']
})
export class ImportComponent implements OnInit {
  readonly inputForm: FormGroup;
  inProgress = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private importService: ImportService,
              private dialogRef: MatDialogRef<ImportComponent>, private toaster: ToastrService) {
    this.inputForm = new FormGroup({
      input: new FormControl('', [Validators.required])
    });
  }

  ngOnInit() {
  }

  onImportClick() {
    this.inProgress = true;
    const [file] = this.inputForm.controls.input.value;

    this.importService.import(file)
      .pipe(tap(() => this.inProgress = false))
      .subscribe(() => {
          this.toaster.success(`Listings successfully imported`, 'Import Listings');
          this.dialogRef.close();
        },
        () => this.toaster.error(`Could not perform import`, 'Import Listings')
      );
  }
}
