import { Component, OnInit } from '@angular/core';
import { AddNewControlService } from '../../directory-edit/add-new-control/add-new-control.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-add-new-control',
  templateUrl: './add-new-control.component.html',
  styleUrls: ['./add-new-control.component.css']
})
export class AddNewControlComponent implements OnInit {
  readonly form: FormGroup;
  constructor(public addControlService: AddNewControlService) {
    this.form = new FormGroup({
      name: new FormControl(),
      value: new FormControl()
    });
  }

  ngOnInit() {}
  submit() {
    const answer = {};
    answer['name'] = this.form.controls['name'].value;
    answer['value'] = this.form.controls['value'].value;

    this.addControlService.componentMethodCallSource.next(answer);
    this.addControlService.addControlForm.close();
  }
}
