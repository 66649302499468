import {Component, Inject} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LocationsService} from '../locations.service';

@Component({
  selector: 'app-location-edit',
  templateUrl: './location-edit.component.html',
  styleUrls: ['./location-edit.component.css']
})
export class LocationEditComponent {
  readonly locationForm: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private locationsService: LocationsService,
              private dialogRef: MatDialogRef<ActivatedRoute>, private toaster: ToastrService) {
    this.locationForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
    });
  }

  onSubmit() {
    this.locationsService.create(this.data.agencyId, this.locationForm.value)
      .subscribe(() => {
          this.dialogRef.close();
          this.toaster.success('Location successfully created', 'Create Location');
        },
        () => this.toaster.error('Unable to create location', 'Create Location'));
  }

}
