import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AddNewControlService } from '../../directory-edit/add-new-control/add-new-control.service';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.css']
})
export class DynamicFormComponent implements OnInit, OnDestroy {
  @Input() rawForm: any;
  form: FormGroup;
  propertyNames: string[];
  jsonForm: { [key: string]: string };
  observ: any;
  constructor(public addControlService: AddNewControlService) {}

  ngOnInit() {
    this.jsonForm = JSON.parse(this.rawForm);

    this.propertyNames = Object.keys(this.jsonForm);
    this.form = new FormGroup(
      this.propertyNames.reduce((accumulator, current) => {
        accumulator[current] = new FormControl(this.jsonForm[current]);
        return accumulator;
      }, {})
    );
    this.observ = this.addControlService.componentMethodCallSource.subscribe(
      answer => {
        this.form.addControl(answer.name, new FormControl(answer.value));
        this.propertyNames = Object.keys(this.form.value);
      }
    );
  }
  ngOnDestroy(): void {
    this.observ.unsubscribe();
  }
  submit() {
    const newControlsArr = this.addControlService.get();
    newControlsArr.forEach(item => {
      this.form.value[item.name] = item.value;
    });
    return JSON.stringify(this.form.value);
  }
  delete(nameInput) {
    this.form.removeControl(nameInput);
    delete this.jsonForm[nameInput];
    this.propertyNames = Object.keys(this.jsonForm);

    this.form = new FormGroup(
      this.propertyNames.reduce((accumulator, current) => {
        accumulator[current] = new FormControl(this.jsonForm[current]);
        return accumulator;
      }, {})
    );
  }
  onSubmissionDataSubmit($event) {}
}
