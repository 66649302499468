import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private readonly baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.api.baseUrl}/users`;
  }

  getUsers(): Observable<User[]> {
    return this.http.get(this.baseUrl, {
      withCredentials: true
    })
      .pipe(map(response => response['content'].users));
  }

  createUser(user: User) {
    return this.http.post(this.baseUrl, {
        data: user
      },
      {
        withCredentials: true,
        responseType: 'text'
      });
  }
}

export interface User {
  username: string;
  password: string;
  officeId: string;
}
