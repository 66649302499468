import {Component, OnInit} from '@angular/core';
import {Office, OfficesService} from './offices.services';
import {LoginService} from '../login/login.service';
import {map, switchMap, tap} from 'rxjs/operators';
import {MatDialog} from '@angular/material';
import {OfficeEditComponent} from './office-edit/office-edit.component';

@Component({
  selector: 'app-offices',
  templateUrl: './offices.component.html',
  styleUrls: ['./offices.component.css']
})
export class OfficesComponent implements OnInit {
  offices: Office[] = [];
  active: false;
  currentOffice = '';

  constructor(private officesService: OfficesService, private loginService: LoginService, private dialog: MatDialog) {
  }

  ngOnInit() {
    this.updateOffices()
      .pipe(switchMap(() => this.loginService.user))
      .pipe()
      .pipe(map(user => this.offices.find(office => office.id === user.officeId)))
      .pipe(tap(office => this.currentOffice = office.id))
      .pipe(map(office => this.officesService.setOffice(office)))
      .subscribe();

    this.officesService.currentOffice.subscribe(office => this.currentOffice = office.id);
  }

  selectOffice(office: Office) {
    if (office.id) {
      this.officesService.setOffice(office);
    } else {
      const dialogRef = this.dialog.open(OfficeEditComponent, {
        width: '400px',
        data: {}
      });

      dialogRef.afterClosed()
        .pipe(switchMap(() => this.updateOffices()))
        .subscribe();
    }
  }

  private updateOffices() {
    return this.officesService.getOffices()
      .pipe(map(offices => offices.concat([{name: '[Add Office]'}])))
      .pipe(map(offices => this.offices = offices));
  }
}
