import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {catchError} from 'rxjs/operators';
import {Validators, FormBuilder, FormGroup} from '@angular/forms';
import {throwError} from 'rxjs';
import {LoginService} from './login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
  showSpinner = false;
  bpForm: number;
  bpOffset: number;
  windHeight: number;
  signupform: FormGroup;
  passwordMinLength = 3;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private loginService: LoginService
  ) {
  }

  ngOnInit() {
    this.bpForm = window.innerWidth <= 420 ? 12 : 6;
    this.bpOffset = window.innerWidth <= 420 ? 0 : 3;
    this.windHeight = window.innerHeight;
    this.signupform = this.formBuilder.group({
      username: ['', Validators.compose([Validators.required])],
      password: ['', Validators.compose([Validators.required])]
    });
  }

  login() {
    this.loginService
      .login({
        username: this.signupform.controls.username.value,
        password: this.signupform.controls.password.value
      })
      .pipe(
        catchError(error => {
          this.signupform.setErrors({
            invalid: true
          });
          return throwError(error);
        })
      )
      .subscribe(() => {
        return this.router.navigate(['agencies']);
      });
  }

  onResize(event) {
    this.bpForm = window.innerWidth <= 420 ? 12 : 6;
    this.bpOffset = window.innerWidth <= 420 ? 0 : 3;
  }
}
